/* a global filtering which must be applied on all email fields - the filter incudes the special allowed characters. */
var glEmailSpecialCharsAllowed = "!#$%&*+-#_@.|'/=? ^`{}~";

$(document).ready(function () {
    window.history.pushState(null, "", window.location.href);
    history.back();
    window.onpopstate = function () { history.go(1); };
    fnClickJacking();
    fnInitNumeric();
    fnInitCurrencyDecimal();
    fnInitPhoneNumeric();
    fnInitTelePhoneNumeric();
    fnInitEmail();
    fnInitFirstName();
    fnInitLastName();
    fnTitle();
    fnCloseThis();
    fnOpenWizard();
    fnNavApplyNow();
    fnBackStep();
    fnDisableRightClick();
    fnInitAnnualIncomeNumeric();
    fnInitCurrencyDecimalForAnnualIncome();
});

// prevent Click jacking
function fnClickJacking() {
    if (self == top) {
        var theBody = document.getElementsByTagName('body')[0];
        theBody.style.display = "block";
    } else {
        top.location = self.location;
    }
}

// Init numeric textboxes
function fnInitNumeric() {
    $(".jqNumeric").numeric();
}

// Init textboxes for currency
function fnInitCurrencyDecimal() {
    $(".cDecimal").autoNumeric({ mNum: 9, mDec: 2 });
}

function fnInitPhoneNumeric() {
    $('.jqPhone').numeric({ allow: ' +' });
}

function fnInitAnnualIncomeNumeric() {
    $('.annualIncome').numeric({ allow: ',' });
}
function fnInitTelePhoneNumeric() {
    $('.jqTelePhone').numeric({ allow: ' +' });
}

function fnInitEmail() {
    $('.jqEmail').alphanumeric({ allow: glEmailSpecialCharsAllowed });
}

function fnInitFirstName() {
    $('.jqFirstName').alphanumeric({ allow: " -&':" });
}

function fnInitLastName() {
    $('.jqLastName').alphanumeric({ allow: " -&':" });
}

function fnTitle() {
    $('.jqTitle').alphanumeric({ allow: " ,.-'()" });
}

function DisplayEventMessage(idSelector, message, className) {
    if (typeof (className) != 'undefined' && className.length > 0) {
        $("#" + idSelector).addClass(className);
    }
    $("#" + idSelector).html(message).show();
}

function fnCloseThis() {
    $(".close").bind('click', function () {
        $(".toHide").hide();
        ShowConfirmModalPopupYesNo($("#divClosePopup").html(), function () { window.close(); }, "");
    });
}

function fnOpenWizard() {
    $("#applyNowBtn").click(function () {
        var obj = {
            resetProductId: false,
            "__RequestVerificationToken": GetToken()
        };
        AjaxCommunication.CreateRequest(
            this.window,
            'POST',
            "/Application/ApplyNow",
            '',
            obj,
            function (result) {
                if (result.Result.Status) {
                    window.location.href = "/Stage/11";
                }
                //            else {
                //                ShowAlertModalPopup(result.EventMessages[0].Value, '');
                //            }
            },
            null
        );
    });
}

function fnNavApplyNow() {
    var resetProductFlag = false;
    $(".btnApplyNow").click(function () {
        var obj = {
            resetProductId: true,
            "__RequestVerificationToken": GetToken()
        };
        AjaxCommunication.CreateRequest(
            this.window,
            'POST',
            "/Application/ApplyNow",
            '',
            obj,
            function (result) {
                if (result.Result.Status) {
                    if (document.location.pathname == '/Application/LandingPage') {
                        resetProductFlag = true;
                        localStorage.setItem('resetProductFlag', resetProductFlag);
                    }
                    else{
                        localStorage.setItem('resetProductFlag', resetProductFlag);
                    }
                    window.location.href = "/Stage/11";
                }
                //            else {
                //                ShowAlertModalPopup(result.EventMessages[0].Value, '');
                //            }
            },
            null
        );
    });
}

function fnBackStep() {
    $(".back").click(function () {
        window.location.href = "/Base/GoToPreviousStage";
    });
}

function openCenteredWindow(url) {
    var width = 1024;
    var height = 786;
    var left = parseInt((screen.availWidth / 2) - (width / 2));
    var top = parseInt((screen.availHeight / 2) - (height / 2));
    var windowFeatures = "width=" + width + ",height=" + height + ",status=0,resizable=1,scrollbars=1,left=" + left + ",top=" + top + "screenX=" + left + ",screenY=" + top;
    myWindow = window.open(url, "subWind", windowFeatures);
}


function AjaxFormCompleteFunction(ajaxContext, formID) {
    $("#formID")[0].validationCallbacks = [];
    $clearHandlers($("#formID")[0]);
    var newDiv = document.createElement("div");
    $(newDiv).html(ajaxContext.get_data());
    var forms = newDiv.getElementsByTagName("frmSavePupil");
    $("#formID").html(forms[0].innerHTML);
    $("#formID")[0].action = forms[0].action;
    Sys.Mvc.FormContext._Application_Load();
    return false;
}

function fnDisableRightClick() {
    $(document).bind("contextmenu", function (e) {
        e.preventDefault();
        return false;
    });
}

// Method to get value from percentage value.
function GetPercentileValueFromString(strValue) {
    var decValue = strValue.replace('%', '') / 100;
    return decValue;
}

// Method to get the ordinal value 
function GetDateOrdinalSuperScript(strValue) {
    var date = strValue.substr(0, strValue.length - 2);
    var ordinal = strValue.substr(strValue.length - 2, 2);

    return date + GetSuperScript(ordinal);
}

// Method to convert text to superscript.
function GetSuperScript(strValue) {
    return '<sup>' + strValue + '</sup>';
}

function GetCurrentSystemDate() {
    var strSysDate = new Date().toLocaleDateString('en-GB');
    if ($.trim(strSysDate).length < 0 || strSysDate == 'undefined') {
        return new Date().toLocaleDateString('en-GB');
    }
    else {
        var dayIndex = strSysDate.indexOf('/');
        var monthIndex = strSysDate.lastIndexOf('/');
        var day;
        var month;
        var year;
        day = strSysDate.substr(0, 2);
        month = strSysDate.substr(dayIndex + 1, monthIndex - dayIndex - 1) - 1;
        year = strSysDate.substr(monthIndex + 1, 4);
        return new Date(year, month, day);
    }
}

function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function fnInitCurrencyDecimalForAnnualIncome() {
    $(".cAnnualIncome").autoNumeric({
        mNum: 9, mDec: 0
    });
}

